import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Row, Col, Container, Form, Button, Alert, Modal, Spinner } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate, NavLink } from "react-router-dom";
import { getDatabase, ref, runTransaction, push,set, get, serverTimestamp } from "firebase/database";

const Withdraw = () => {
  const navigate = useNavigate();
  const { loggedInUser, updateBalance, loading, setLoading } = useContext(BankContext);
  const [withdraw, setWithdraw] = useState(localStorage.getItem("total") || "");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const alertShown = useRef(false);

  useEffect(() => {
    if (!loggedInUser && !alertShown.current) {
      alertShown.current = true;
      alert("Please Login");
      navigate("/login");
    }
  }, [loggedInUser, navigate]);

  const handleWithdraw = async (e) => {
    e.preventDefault();
    const withdrawAmount = parseFloat(withdraw);

    if (!loggedInUser) {
      setError("Please login first");
      return;
    }
    if (!loggedInUser?.isWithdraw && localStorage.getItem("isBuy") === "false") {
      setError("You can't withdraw points. Please contact admin to activate withdraw feature.");
      return;
    }
    if (isNaN(withdrawAmount)) {
      setError("Please enter a valid number");
      return;
    }
    if (withdrawAmount <= 0) {
      setError("Withdraw amount must be positive");
      return;
    }

    const totalBalance = (loggedInUser.balance || 0) + (loggedInUser.transferBalance || 0);
    const isBuying = localStorage.getItem("isBuy") === "true";

    if (isBuying) {
      if (withdrawAmount > loggedInUser.transferBalance) {
        setError("Insufficient funds in your transfer balance for this purchase");
        return;
      }
    } else {
      if (withdrawAmount > loggedInUser.balance) {
        setError("Insufficient funds");
        return;
      }
    }

    setLoading(true);

    try {
      await updateBalance(withdrawAmount, isBuying ? "purchase" : "withdraw");
      if (isBuying) {
        await handlePurchase(withdrawAmount);
      }
      setShowModal(true);
    } catch (error) {
      console.error("Error during withdrawal:", error);
      setError("Something went wrong with the withdrawal.");
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = async (amount) => {
    const db = getDatabase();
    const cartRef = ref(db, `carts/${loggedInUser.email.replace(/\./g, ',')}`);
    const purchasedItemsRef = ref(db, `purchasedItems/${loggedInUser.email.replace(/\./g, ',')}`);
    
    try {
      const cartSnapshot = await get(cartRef);
      if (cartSnapshot.exists()) {
        const cartData = cartSnapshot.val();
        
        // Add purchase to purchasedItems
        const newPurchaseRef = push(purchasedItemsRef);
        await set(newPurchaseRef, {
          items: cartData,
          purchaseDate: serverTimestamp(),
          totalAmount: amount
        });
        
        // Update the inventory purchase count for each item in the cart
        for (const key in cartData) {
          const cartItem = cartData[key];
          const giftItemRef = ref(db, `gifts/${cartItem.giftItemId}`);
          
          // Transaction to increment the number of purchases
          await runTransaction(giftItemRef, (currentGiftItem) => {
            if (currentGiftItem) {
              if (!currentGiftItem.numberOfPurchased) {
                currentGiftItem.numberOfPurchased = 0;
              }
              currentGiftItem.numberOfPurchased += cartItem.quantity;
            }
            return currentGiftItem;
          });
        }
        
        // Clear the cart
        await set(cartRef, null);
  
        localStorage.setItem("isBuy", "false");
        localStorage.removeItem("total");
      } else {
        throw new Error("Cart is empty");
      }
    } catch (error) {
      console.error("Error processing purchase:", error);
      throw error;
    }
  };

  const handleConfirm = () => {
    setWithdraw("");
    localStorage.removeItem("total");
    localStorage.setItem("isBuy", "false");
    setError("");
    setShowModal(false);
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    if (!loggedInUser && !alertShown.current) {
      alertShown.current = true;
      alert("Please Login");
      navigate("/login");
    }
  }, [loggedInUser, navigate]);


  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} lg={9} className="order-lg-1">
            <Card className="text-left" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Body>
                <Card.Title>Withdraw Appreciate360 Point(s)</Card.Title>

                <Form onSubmit={handleWithdraw}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Account Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={`${loggedInUser?.firstName || 'Unknown'} ${loggedInUser?.lastName || 'User'}`}
                      disabled
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Account Balance</Form.Label>
                    <Form.Control
                      type="text"
                      value={`${((loggedInUser?.balance || 0) + (loggedInUser?.transferBalance || 0)).toFixed(2)} point(s)`}
                      disabled
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Appreciation Balance</Form.Label>
                    <Form.Control
                      type="text"
                      value={`${(loggedInUser?.transferBalance || 0).toFixed(2)} point(s)`}
                      disabled
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Withdraw Amount</Form.Label>
                    <Form.Control
                      type="text"
                      value={withdraw}
                      onChange={(e) => setWithdraw(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!withdraw}
                    style={{ marginTop: "30px", background: "red", border: "none" }}
                  >
                    Withdraw 
                  </Button>
                </Form>
                {error && <Alert variant="danger">{error}</Alert>}
              </Card.Body>
            </Card>

            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Body>
                <Card.Title>
                  <h5>How to manage your Appreciate360 Point(s) Withdrawal</h5>
                </Card.Title>
                <p style={{ textAlign: 'left', fontSize: '14px'}}>
                  The Appreciate360 system in coAmplifi manages withdrawals by accounting for all the points in your spending account, which are points gifted to you by other users. You will not be able to withdraw or use points from your general account balance unless they are in your spending account. Your general balance includes a combination of points from regular credits and points given to you by other users. However, only the points gifted to you are available in your spending account for withdrawals. Points from your general account can also be gifted to other users as a gesture of appreciation.
                </p>
              </Card.Body>
            </Card>
          </Col>    

          <Col xs={12} lg={3} className="order-lg-2">
            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Header>Welcome to your Appreciate360 v7.1.5</Card.Header>
              <Card.Body>
                <Card.Title style={{ marginBottom: '10px' }}>
                  {!loggedInUser ? (
                    'Please Login to view your account information.'
                  ) : (
                    <p style={{ textAlign: 'left', fontSize: '14px'}}>
                      You are currently logged in to your Appreciate360 account in coAmplifi. <br/><br/>
                      You may execute transactions in your account using the tabs in the navigation menu.
                    </p>
                  )}
                </Card.Title>
              </Card.Body>
            </Card>

            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Header>Appreciate360 Information</Card.Header>
              <Card.Body>
                <Card.Title style={{ marginBottom: '10px' }}>
                  {loggedInUser ? (
                    <ul style={{ textAlign: 'left', fontSize: '14px', listStyleType: 'circle' }}>
                      <li>
                        <NavLink to="/home">Home</NavLink> - Access your home page by selecting the coAmplifi logo.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/deposit">Deposit</NavLink> - Add points into your account and see the increase in your balance.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/withdraw">Withdraw</NavLink> - Subtract points from your account and see the decrease in your balance.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/transfer">Appreciate</NavLink> - Move points from your balance to another user and see the points added to their account and subtracted from yours.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/giftshop">Gift Shop</NavLink> - Checkout items in the giftshop that you might be interested in.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/cart">Cart</NavLink> - See your cart and its contents ready to buy.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/purchased-items">Purchased</NavLink> - See purchases that have occurred in your account.
                      </li>
                    </ul>
                  ) : (
                    'Please login to see account-related information.'
                  )}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {loading ? (
        <Spinner />
      ) : (
        <Modal show={showModal} onHide={handleConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Successfully Withdrawn</Modal.Title>
        </Modal.Header>
        <Modal.Body>{`Successfully withdrew ${withdraw} point(s) from your account. 
         Your new balance is ${((loggedInUser?.balance || 0) + (loggedInUser?.transferBalance || 0)).toFixed(2)} point(s).`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleConfirm}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </>
);
};

export default Withdraw;