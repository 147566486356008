import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Col, Row, Container, Button } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate, NavLink } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";

const Home = () => {
  const navigate = useNavigate();
  const { loggedInUser } = useContext(BankContext);
  const alertShown = useRef(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(20);
  const [transactions, setTransactions] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (!loggedInUser && !alertShown.current) {
      alertShown.current = true;
      alert("Please Login");
      navigate("/login");
    }
  }, [loggedInUser, navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (loggedInUser) {
        const db = getDatabase();
        try {
          const userRef = ref(db, `users/${loggedInUser.email.replace(/\./g, ',')}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const fetchedUserData = snapshot.val();
            setUserData(fetchedUserData);
            
            if (fetchedUserData.transactions) {
              const transactionArray = Object.entries(fetchedUserData.transactions).map(([key, value]) => ({
                id: key,
                ...value
              }));
              setTransactions(transactionArray);
            } else {
              setTransactions([]);
            }
          }
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        }
      }
    };

    fetchUserData();
  }, [loggedInUser]);

  // Sort transactions in reverse chronological order
  const sortedTransactions = transactions.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  // Get current transactions
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = sortedTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <Container fluid className="px-3 py-3">
      <Row>
        <Col xs={12} lg={9} className="mb-4 mb-lg-0">
          <Card className="mb-4">
            <Card.Header as="h5" className="text-center">Account Summary</Card.Header>
            <Card.Body>
              <h4 className="mb-3">Account Name: {loggedInUser?.firstName || 'N/A'} {loggedInUser?.lastName || 'N/A'}</h4>
              <h5 className="mb-2">Account Balance: {`${((loggedInUser?.balance || 0) + (loggedInUser?.transferBalance || 0)).toFixed(2)} point(s)`}</h5>
              <h5 className="mb-3">Appreciate360 Balance: {(loggedInUser?.transferBalance || 0)} point(s)</h5>
            </Card.Body>
          </Card>

          {loggedInUser ? (
            <Card>
              <Card.Body>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Earned Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{loggedInUser.firstName || 'N/A'}</td>
                        <td>{loggedInUser.lastName || 'N/A'}</td>
                        <td>{loggedInUser.email || 'N/A'}</td>
                        <td>****</td>
                        <td>{loggedInUser.balance || 0} point(s)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {currentTransactions.length > 0 ? (
                  <>
                    <h5 className="mt-4 mb-3">Transaction History</h5>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Running Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTransactions.map((entry) => (
                            <tr key={entry.id}>
                              <td>{new Date(entry.date).toLocaleString()}</td>
                              <td>
                                <span style={{ textTransform: "capitalize" }}>{entry.type}</span>
                                {(entry.type === "send" || entry.type === "receive")}
                              </td>
                              <td>{entry.amount} point(s)</td>
                              <td>{parseFloat(entry.runningBalance).toFixed(2)} point(s)</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      transactionsPerPage={transactionsPerPage}
                      totalTransactions={sortedTransactions.length}
                      paginate={paginate}
                    />
                  </>
                ) : (
                  <p className="mt-3">No transactions found.</p>
                )}
              </Card.Body>
            </Card>
          ) : (
            <Card.Title className="text-center my-5">
              Please Login to view your account information.
            </Card.Title>
          )}
        </Col>

        <Col xs={12} lg={3}>
          <Card className="mb-4">
            <Card.Header as="h5" className="text-center">Welcome to your Appreciate360</Card.Header>
            <Card.Body>
              {!loggedInUser ? (
                <p>Please Login to view your account information.</p>
              ) : (
                <p>You are currently logged in to your Appreciate360 account coAmplifi. You may execute transactions in your account using the tabs in the navigation menu.</p>
              )}
            </Card.Body>
          </Card>

          <Card>
            <Card.Header as="h5" className="text-center">Appreciate360 Information</Card.Header>
            <Card.Body>
              {loggedInUser ? (
                <ul className="list-unstyled">
                  <li><NavLink to="/home">Home</NavLink> - Access your home page</li>
                  <li><NavLink to="/deposit">Deposit</NavLink> - Add points to your account</li>
                  <li><NavLink to="/withdraw">Withdraw</NavLink> - Subtract points from your account</li>
                  <li><NavLink to="/transfer">Appreciate</NavLink> - Move points to another user</li>
                  <li><NavLink to="/giftshop">Gift Shop</NavLink> - Browse items in the gift shop</li>
                  <li><NavLink to="/cart">Cart</NavLink> - View your cart contents</li>
                  <li><NavLink to="/purchased-items">Purchased</NavLink> - View your purchase history</li>
                </ul>
              ) : (
                <p>Please login to see account-related information.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Pagination = ({ transactionsPerPage, totalTransactions, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalTransactions / transactionsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav aria-label="Transaction page navigation" className="mt-4">
      <ul className="pagination justify-content-center flex-wrap">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item m-1">
            <Button onClick={() => paginate(number)} className="page-link">
              {number}
            </Button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Home;