import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, get, update } from "firebase/database";

const AllData = () => {
  const navigate = useNavigate();
  const { users, loggedInUser, setUsers, updateFields } = useContext(BankContext);
  const alertShown = useRef(false);
  const alertShown2 = useRef(false);
  const [salaryInputs, setSalaryInputs] = useState({});
  const [renderedTransactions, setRenderedTransactions] = useState({});

  useEffect(() => {
    if (!loggedInUser && !alertShown.current) {
      alertShown.current = true;
      alert("Please Login");
      navigate("/login");
    } else {
      const fetchUsers = async () => {
        const db = getDatabase();
        const usersRef = ref(db, "users");
        try {
          const snapshot = await get(usersRef);
          if (snapshot.exists()) {
            const userList = Object.entries(snapshot.val()).map(([emailKey, user]) => ({
              _id: emailKey,
              balance: user.balance || 0,
              transferBalance: user.transferBalance || 0,
              ...user,
            }));
            setUsers(userList);
          } else {
            console.warn("No user data found.");
          }
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      };
      fetchUsers();
    }
  }, [loggedInUser, navigate, setUsers]);

  const handleActiveChange = async (emailKey, field) => {
    try {
      const db = getDatabase();
      const userRef = ref(db, `users/${emailKey}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const currentValue = snapshot.val()[field];
        const newValue = !currentValue;
        
        // Update local state immediately
        setUsers(prevUsers => 
          prevUsers.map(user => 
            user._id === emailKey ? { ...user, [field]: newValue } : user
          )
        );
  
        // Update database
        await update(userRef, { [field]: newValue });
      }
    } catch (error) {
      console.log("Error updating field:", error);
      // Revert local state if database update fails
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user._id === emailKey ? { ...user, [field]: !user[field] } : user
        )
      );
    }
  };

  const handleSalaryInput = (e, emailKey) => {
    const { value } = e.target;
    setSalaryInputs((prev) => ({
      ...prev,
      [emailKey]: value,
    }));
  };

  const handleProcessSalary = async (emailKey) => {
    const salary = parseFloat(salaryInputs[emailKey]);
    if (isNaN(salary) && !alertShown2.current) {
      alertShown2.current = true;
      alert("Please enter a valid salary amount");
    } else {
      try {
        const db = getDatabase();
        const userRef = ref(db, `users/${emailKey}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const currentSalary = snapshot.val().salary || 0;
          await update(userRef, {
            salary: currentSalary + salary,
            isSalaryReceived: true
          });
          alert("Salary processed successfully");
          setSalaryInputs((prev) => ({
            ...prev,
            [emailKey]: "",
          }));
          // Fetch updated user data
          const updatedSnapshot = await get(userRef);
          if (updatedSnapshot.exists()) {
            setUsers((prevUsers) =>
              prevUsers.map((user) =>
                user._id === emailKey
                  ? { ...user, salary: updatedSnapshot.val().salary, isSalaryReceived: true }
                  : user
              )
            );
          }
        }
      } catch (error) {
        console.log("Error processing salary:", error);
        alert("An error occurred while processing salary.");
      }
    }
  };

  const handleProcessSalaryInBulk = async () => {
    const usersWithSalary = Object.keys(salaryInputs).filter(
      (emailKey) => salaryInputs[emailKey]
    );
    if (usersWithSalary.length === 0) {
      alert("No users have a salary value entered.");
      return;
    }

    try {
      const db = getDatabase();
      const updates = {};
      for (const emailKey of usersWithSalary) {
        const userRef = ref(db, `users/${emailKey}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const currentSalary = snapshot.val().salary || 0;
          const newSalary = currentSalary + parseFloat(salaryInputs[emailKey]);
          updates[`users/${emailKey}/salary`] = newSalary;
          updates[`users/${emailKey}/isSalaryReceived`] = true;
        }
      }
      await update(ref(db), updates);
      alert("Salaries processed successfully");
      setSalaryInputs({});
      // Fetch updated user data
      const snapshot = await get(ref(db, "users"));
      if (snapshot.exists()) {
        const userList = Object.entries(snapshot.val()).map(([emailKey, user]) => ({
          _id: emailKey,
          balance: user.balance || 0,
          transferBalance: user.transferBalance || 0,
          salary: user.salary || 0,
          isSalaryReceived: user.isSalaryReceived || false,
          ...user,
        }));
        setUsers(userList);
      }
    } catch (error) {
      console.log("Error processing salaries in bulk:", error);
      alert("An error occurred while processing salaries.");
    }
  };


  const renderTransactions = async (user) => {
    if (!user.transactions || typeof user.transactions !== 'object') {
      console.log('No transactions found for user:', user.email);
      return null;
    }
  
    const transactionEntries = Object.entries(user.transactions);
    if (transactionEntries.length === 0) {
      console.log('Empty transactions array for user:', user.email);
      return null;
    }
  
    // Sort transactions by date
    const sortedTransactions = transactionEntries.sort((a, b) => new Date(b[1].date) - new Date(a[1].date));
  
    const getOtherPartyEmail = async (transactionKey, transactionType, currentUserKey) => {
      const db = getDatabase();
    
      try {
        const usersRef = ref(db, 'users');
        const usersSnapshot = await get(usersRef);
        
        if (usersSnapshot.exists()) {
          const usersData = usersSnapshot.val();
    
          const otherParty = Object.entries(usersData).find(([userKey, userData]) => {
            if (userKey === currentUserKey) return false;
            return userData.transactions && userData.transactions[transactionKey];
          });
    
          if (otherParty) {
            return otherParty[1].email || `Unknown ${transactionType === 'send' ? 'Receiver' : 'Sender'}`;
          }
        }
      } catch (error) {
        console.error("Error fetching other party's information:", error);
      }
    
      return 'Another User'; // Fallback if email cannot be retrieved
    };
  
    const renderedTransactions = await Promise.all(
      sortedTransactions.map(async ([key, entry]) => {
        const otherPartyEmail = (entry.type === "send" || entry.type === "receive") 
          ? await getOtherPartyEmail(key, entry.type, user._id)
          : null;
        
        return (
          <Row key={key}>
            <Col md={4}>{new Date(entry.date).toLocaleString()}</Col>
            <Col md={4} style={{ textTransform: "capitalize" }}>
              {entry.type}
              {otherPartyEmail && ` (${entry.type === "send" ? "to" : "from"} ${otherPartyEmail})`}
            </Col>
            <Col md={2}>{entry.amount} point(s)</Col>
            <Col md={2}>{parseFloat(entry.runningBalance).toFixed(2)} point(s)</Col>
          </Row>
        );
      })
    );
  
    return (
      <Row style={{ border: "1px solid black", marginLeft: "200px" }}>
        <Row style={{ fontWeight: "bold" }}>
          <Col md={4}>Transactions</Col>
          <Col md={4}>Type</Col>
          <Col md={2}>Amount</Col>
          <Col md={2}>Running Balance</Col>
        </Row>
        {renderedTransactions}
      </Row>
    );
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      const transactions = {};
      for (const user of users) {
        transactions[user._id] = await renderTransactions(user);
      }
      setRenderedTransactions(transactions);
    };

    if (users.length > 0) {
      fetchTransactions();
    }
  }, [users]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>All Data</Card.Title>
        <Card.Text>
          {loggedInUser?.email === "admin@curaesoft.com" ? (
            <Row
              style={{
                fontWeight: "bold",
                borderBottom: "1px solid black",
                marginTop: "20px",
              }}
            >
              <Col md={1}>Logged In</Col>
              <Col md={1}>First Name</Col>
              <Col md={1}>Last Name</Col>
              <Col md={2}>Email</Col>
              <Col md={2}>Password</Col>
              <Col md={1}>Balance</Col>
              <Col md={1}>Active</Col>
              <Col md={1}>Withdraw</Col>
              <Col md={1}>Deposit</Col>
              <Col md={1}>Transfer</Col>
            </Row>
          ) : (
            <Row
              style={{
                fontWeight: "bold",
                borderBottom: "1px solid black",
                marginTop: "20px",
              }}
            >
              <Col md={2}>Logged In</Col>
              <Col md={2}>First Name</Col>
              <Col md={2}>Last Name</Col>
              <Col md={2}>Email</Col>
              <Col md={2}>Password</Col>
              <Col md={2}>Balance</Col>
            </Row>
          )}
          {users?.map((user, index) => (
            <Col key={index}>
              {loggedInUser?.email === "admin@curaesoft.com" ? (
                <Row style={{ paddingTop: "20px" }}>
                  <Col md={1}>
                    <input type="checkbox" checked={user.isLogin} readOnly />
                  </Col>
                  <Col md={1}>{user.firstName}</Col>
                  <Col md={1}>{user.lastName}</Col>
                  <Col md={2}>{user.email}</Col>
                  <Col md={2}>{user.password}</Col>
                  <Col md={1}>{user.balance + (user.transferBalance || 0)} point(s)</Col>
                  <Col md={1}>
                    <input
                      type="checkbox"
                      checked={user?.isActive}
                      onChange={() => handleActiveChange(user._id, "isActive")}
                    />
                  </Col>
                  <Col md={1}>
                    <input
                      type="checkbox"
                      checked={user?.isWithdraw}
                      onChange={() => handleActiveChange(user._id, "isWithdraw")}
                    />
                  </Col>
                  <Col md={1}>
                    <input
                      type="checkbox"
                      checked={user?.isDeposit}
                      onChange={() => handleActiveChange(user._id, "isDeposit")}
                    />
                  </Col>
                  <Col md={1}>
                    <input
                      type="checkbox"
                      checked={user?.isTransfer}
                      onChange={() => handleActiveChange(user._id, "isTransfer")}
                    />
                  </Col>
                </Row>
              ) : (
                <Row style={{ paddingTop: "20px" }}>
                  <Col md={2}>
                    <input type="checkbox" checked={user.isLogin} readOnly />
                  </Col>
                  <Col md={2}>{user.firstName}</Col>
                  <Col md={2}>{user.lastName}</Col>
                  <Col md={2}>{user.email}</Col>
                  <Col md={2}>
                    {user?._id === loggedInUser?._id ? user.password : "*******"}
                  </Col>
                  <Col md={2}>{user.balance + (user.transferBalance || 0)} point(s)</Col>
                </Row>
              )}
              {renderedTransactions[user._id]}
              {loggedInUser?.email === "admin@curaesoft.com" &&
                user?.email !== "admin@curaesoft.com" && (
                  <Row style={{ marginLeft: "200px", marginTop: "20px" }}>
                    <Col md={3}>
                      <span style={{ fontWeight: "bold" }}>Send Points</span>
                    </Col>
                    <Col md={6}>
                      <input
                        type="text"
                        style={{ width: "200px" }}
                        value={salaryInputs[user._id] || ""}
                        onChange={(e) => handleSalaryInput(e, user._id)}
                      />
                    </Col>
                    <Col md={3}>
                      <Button
                        style={{ background: "red", border: "none" }}
                        variant="primary"
                        type="submit"
                        onClick={() => handleProcessSalary(user._id)}
                        disabled={!salaryInputs[user._id]}
                      >
                        Process Points
                      </Button>
                    </Col>
                  </Row>
                )}
            </Col>
          ))}
        </Card.Text>
        {loggedInUser?.email === "admin@curaesoft.com" && (
          <Button
            style={{ background: "red", border: "none" }}
            variant="primary"
            type="submit"
            onClick={() => handleProcessSalaryInBulk()}
            disabled={
              Object.keys(salaryInputs).filter((userId) => salaryInputs[userId])
                .length <= 0
            }
          >
            Process All Entered Bonus Points
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default AllData;