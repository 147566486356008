import React, { useState } from 'react';
import { Card, Col, Row, Container, Button } from 'react-bootstrap';
import { getDatabase, ref, child, get } from 'firebase/database';
import axios from 'axios';

const Chatbot = () => {
  const [message, setMessage] = useState('');
  const [chat, setChat] = useState([]);

  const handleSend = async () => {
    if (message.trim() === '') return;

    setChat([...chat, { sender: 'user', text: message }]);

    try {
      const messageKey = message;
      let context = '';

      // Fetch context from Firebase Realtime Database
      try {
        const dbRef = ref(getDatabase());
        const manualSnapshot = await get(child(dbRef, `chatbotmanual/${encodeURIComponent(messageKey)}`));
        if (manualSnapshot.exists()) {
          context = manualSnapshot.val().content;
        } else {
          console.log('No context found for the key:', messageKey);
        }
      } catch (manualError) {
        console.error('Error fetching manual data from Firebase:', manualError.message);
      }

      // Prepare the message for OpenAI API
      const promptMessages = [
        { role: 'system', content: context ? `Context: ${context}` : 'No additional context available.' },
        { role: 'user', content: message }
      ];

      // Send message to OpenAI API
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: 'gpt-3.5-turbo',
        messages: promptMessages,
        max_tokens: 150,
        temperature: 0.9,
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      const botMessage = response.data.choices[0].message.content.trim();
      setChat([...chat, { sender: 'user', text: message }, { sender: 'bot', text: botMessage }]);
      setMessage('');

    } catch (error) {
      console.error('Error communicating with OpenAI API:', error.response ? error.response.data : error.message);
      setChat([...chat, { sender: 'user', text: message }, { sender: 'bot', text: 'Sorry, I could not fetch the information you requested.' }]);
    }
  };

  return (
    <Container fluid>
      <Row>
        {/* Main Chat Area */}
        <Col xs={12} lg={9} className="order-lg-1">
          <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
            <Card.Header>WELCOME TO CSPOINTS SUPPORT!!! Chat with Bart (Business Assistant in Real Time), your AI Assistant.</Card.Header>
            <Card.Body>
              <div style={{ border: '1px solid #ccc', padding: '10px', height: '400px', overflowY: 'scroll' }}>
                {chat.map((entry, index) => (
                  <div key={index} style={{ textAlign: entry.sender === 'user' ? 'right' : 'left' }}>
                    <strong>{entry.sender === 'user' ? 'You' : 'Bart'}: </strong>
                    {entry.text}
                  </div>
                ))}
              </div>
              <div style={{ marginTop: '20px' }}>
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                  style={{ width: '80%', marginRight: '10px' }}
                />
                <Button onClick={handleSend} style={{ width: '18%' }}>Send</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Side Information Area */}
        <Col xs={12} lg={3} className="order-lg-2">
          <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
            <Card.Header>Chat Information</Card.Header>
            <Card.Body>
              <Card.Text>
                You can ask questions or interact with Bart, our Chatbot, here. Bart is an AI assistant that is always available to you 24/7.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
            <Card.Header>Additional Resources</Card.Header>
            <Card.Body>
              <Card.Text>
                You are currently using CSPoints 6.5.6G. Check out our help section for more information.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Chatbot;