import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, get, remove, update } from "firebase/database";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { storage } from "../firebase/firebase";

const Cart = () => {
  const { loggedInUser, setLoading, loading } = useContext(BankContext);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [carts, setCarts] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchCartItems();
  }, [loggedInUser]);

  const fetchCartItems = async () => {
    if (!loggedInUser) {
      navigate("/login");
      return;
    }

    setLoading(true);
    try {
      const db = getDatabase();
      const cartRef = ref(db, `carts/${loggedInUser.email.replace(/\./g, ',')}`);
      const giftsRef = ref(db, 'gifts');
      const [cartSnapshot, giftsSnapshot] = await Promise.all([get(cartRef), get(giftsRef)]);

      if (cartSnapshot.exists() && giftsSnapshot.exists()) {
        const cartItems = cartSnapshot.val();
        const gifts = giftsSnapshot.val();
        let fetchedCarts = [];
        let calculatedTotal = 0;

        for (let key in cartItems) {
          const cartItem = cartItems[key];
          const gift = gifts[cartItem.giftItemId];
          let imageUrl = "/path/to/default/image.jpg";

          if (cartItem.imagePath) {
            try {
              imageUrl = await getDownloadURL(storageRef(storage, cartItem.imagePath));
            } catch (imageError) {
              console.error("Error fetching image:", imageError);
            }
          }

          const currentInventory = gift ? (gift.quantity !== undefined ? parseInt(gift.quantity) : 0) : 0;
          
          fetchedCarts.push({
            ...cartItem,
            _id: key,
            image: imageUrl,
            inStock: currentInventory > 0,
            currentInventory: currentInventory,
          });

          if (currentInventory > 0) {
            calculatedTotal += parseFloat(cartItem.price) * Math.min(cartItem.quantity, currentInventory);
          }
        }

        console.log("Fetched cart items:", fetchedCarts);
        setCarts(fetchedCarts);
        setTotal(calculatedTotal);
      } else {
        setCarts([]);
        setTotal(0);
      }
    } catch (err) {
      console.error("Failed to load cart items:", err);
      setError("Failed to load cart items. Please try again.");
    } finally {
      setLoading(false);
    }
  };

    const handleBuyNow = () => {
      const inStockItems = carts.filter(item => item.inStock);
      if (inStockItems.length > 0) {
        localStorage.setItem("total", total.toString());
        localStorage.setItem("isBuy", "true");
        navigate("/withdraw");
      } else {
        setError("Items in your cart are currentlynot in stock.");
      }
    };
  
    if (loading) {
      return <Spinner animation="border" />;
    }

  const handleRemove = async (cartItemId) => {
    if (!loggedInUser) return;

    setLoading(true);
    try {
      const db = getDatabase();
      const cartItemRef = ref(db, `carts/${loggedInUser.email.replace(/\./g, ',')}/${cartItemId}`);
      await remove(cartItemRef);

      setCarts(prevCarts => {
        const updatedCarts = prevCarts.filter(item => item._id !== cartItemId);
        const removedItem = prevCarts.find(item => item._id === cartItemId);
        setTotal(prevTotal => prevTotal - (parseFloat(removedItem?.price) * removedItem?.quantity || 0));
        return updatedCarts;
      });

      alert("Removed from Cart Successfully");
    } catch (err) {
      console.error("Failed to remove from cart:", err);
      setError("Failed to remove from cart. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Cart</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {total > 0 && (
          <Row className="mb-3">
            <Col md={12}>
              <div className="d-flex justify-content-end align-items-center">
                <span className="me-3" style={{ fontSize: "20px" }}>
                  Total: <strong>{total.toFixed(2)}</strong> point(s)
                </span>
                <Button variant="danger" onClick={handleBuyNow}>
                  BUY NOW!
                </Button>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          {carts.length === 0 ? (
            <Col>Your cart is empty.</Col>
          ) : (
            carts.map((cart) => (
              <Col key={cart._id} md={4} className="mb-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={cart.image}
                    style={{ height: "200px", objectFit: "contain", padding: "10px" }}
                  />
                  <Card.Body>
                    <Card.Title>{cart.name}</Card.Title>
                    <Card.Text>{cart.description}</Card.Text>
                    <Card.Text>{cart.price} point(s)</Card.Text>
                    <Card.Text>Quantity: {cart.quantity}</Card.Text>
                    {!cart.inStock && (
                      <Alert variant="danger">
                        Sorry, this item is now out of stock.
                      </Alert>
                    )}
                    {cart.inStock && cart.currentInventory < cart.quantity && (
                      <Alert variant="warning">
                        Only {cart.currentInventory} item(s) available.
                      </Alert>
                    )}
                    <Button
                      variant="danger"
                      onClick={() => handleRemove(cart._id)}
                    >
                      Remove From Cart
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Cart;



