import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { Alert, Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { getDatabase, ref, update, onValue, get, off } from "firebase/database";

const Inventory = () => {
  const { setLoading, loading, loggedInUser } = useContext(BankContext);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [enteredQuantity, setEnteredQuantity] = useState(0);
  const [inventory, setInventory] = useState([]);
  const [purchaseDetails, setPurchaseDetails] = useState([]);
  const [selectedGiftItem, setSelectedGiftItem] = useState(null);
  const [isInventoryLoaded, setIsInventoryLoaded] = useState(false);
  const [isPurchaseDetailsLoading, setIsPurchaseDetailsLoading] = useState(false);
  const navigate = useNavigate();
  const mountedRef = useRef(true);

  const fetchPurchaseDetails = useCallback(async () => {
    if (!mountedRef.current || !loggedInUser || loggedInUser.email !== "admin@curaesoft.com") return;

    setIsPurchaseDetailsLoading(true);
    const db = getDatabase();
    const purchasedItemsRef = ref(db, "purchasedItems");

    try {
      const snapshot = await get(purchasedItemsRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log("Fetched purchase data:", data);

        const detailsArray = [];
        const purchaseCounts = {};

        Object.entries(data).forEach(([userEmail, purchases]) => {
          Object.entries(purchases).forEach(([purchaseId, purchase]) => {
            Object.entries(purchase.items).forEach(([itemId, item]) => {
              const purchaseCount = parseInt(item.quantity) || 0;

              detailsArray.push({
                giftItem: { name: item.name, id: itemId },
                user: { email: userEmail.replace(/,/g, '.') },
                count: purchaseCount,
                currentPrice: item.price,
                date: purchase.purchaseDate,
              });

              if (!purchaseCounts[item.name]) {
                purchaseCounts[item.name] = 0;
              }
              purchaseCounts[item.name] += purchaseCount;
            });
          });
        });

        console.log("Purchase counts:", purchaseCounts);

        setInventory(prevInventory =>
          prevInventory.map(item => ({
            ...item,
            numberOfPurchased: purchaseCounts[item.name] || 0
          }))
        );

        setPurchaseDetails(detailsArray);
      } else {
        setPurchaseDetails([]);
      }
    } catch (error) {
      console.error("Failed to load purchase details:", error);
      setError("Failed to load purchase details.");
    } finally {
      setIsPurchaseDetailsLoading(false);
    }
  }, [loggedInUser]);

  const fetchInventory = useCallback(async () => {
    if (!mountedRef.current || !loggedInUser || loggedInUser.email !== "admin@curaesoft.com") return;

    setLoading(true);
    const db = getDatabase();
    const giftItemsRef = ref(db, "gifts");

    try {
      const snapshot = await get(giftItemsRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const inventoryArray = Object.entries(data).map(([key, value]) => ({
          _id: key,
          name: value.name,
          ...value,
          quantity: parseInt(value.quantity) || 0,
          numberOfPurchased: 0
        }));
        console.log("Fetched inventory data:", inventoryArray);
        setInventory(inventoryArray);
        setIsInventoryLoaded(true);
        fetchPurchaseDetails(); // Start loading purchase details after inventory is loaded
      } else {
        setInventory([]);
        setIsInventoryLoaded(true);
      }
    } catch (error) {
      console.error("Failed to load inventory:", error);
      setError("Failed to load inventory.");
    } finally {
      setLoading(false);
    }
  }, [loggedInUser, fetchPurchaseDetails, setLoading]);

  useEffect(() => {
    if (!loggedInUser) {
      alert("Please Login");
      navigate("/login");
    } else if (loggedInUser.email !== "admin@curaesoft.com") {
      alert("You don't have permission to access this page");
      navigate("/home");
    } else {
      fetchInventory();
    }

    return () => {
      mountedRef.current = false;
    };
  }, [loggedInUser, navigate, fetchInventory]);

  // Handles submission for adding more inventory items
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!mountedRef.current || !loggedInUser || loggedInUser.email !== "admin@curaesoft.com") return;

    try {
      setLoading(true);
      const db = getDatabase();

      if (selectedGiftItem) {
        const giftItemRef = ref(db, `gifts/${selectedGiftItem._id}`);
        const newQuantity = (parseInt(selectedGiftItem.quantity) || 0) + parseInt(enteredQuantity);
        await update(giftItemRef, {
          quantity: newQuantity,
        });

        console.log(`Updated quantity for ${selectedGiftItem.name}: ${newQuantity}`);
        alert("Added More Items Successfully");
        fetchInventory(() => {
          setLoading(false);
        });
        setShowModal(false);
        setEnteredQuantity(0);
      }
    } catch (err) {
      alert("Something went wrong: " + err.message);
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Inventory</Card.Title>
          {loading ? (
            <Spinner animation="border" />
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : (
            <>
            <Row style={{ fontWeight: "bold" }}>
              <Col md={3}>Gift Item Name</Col>
              <Col md={3}>Quantity in Stock</Col>
              <Col md={3}>Number of Purchased</Col>
              <Col md={3}></Col>
            </Row>
            {inventory.map((item, index) => (
                <Row key={index} style={{ marginTop: "20px" }}>
                  <Col md={3}>{item.name}</Col>
                  <Col md={3}>{item.quantity}</Col>
                  <Col md={3}>{item.numberOfPurchased}</Col>
                  <Col md={3}>
                    <Button
                      style={{ background: "red", border: "none" }}
                      onClick={() => {
                        setSelectedGiftItem(item);
                        setShowModal(true);
                      }}
                >
                  Add More
                </Button>
              </Col>
            </Row>
          ))}
          </>
          )}
        </Card.Body>
      </Card>
      <Card style={{ marginTop: "30px" }}>
        <Card.Body>
          <Card.Title>Purchased Details of Gift Items</Card.Title>
          {isPurchaseDetailsLoading ? (
            <Spinner animation="border" />
          ) : (
            <>
            <Row style={{ fontWeight: "bold" }}>
              <Col md={3}>Gift Item Name</Col>
              <Col md={3}>Purchased By</Col>
              <Col md={2}>Purchased Quantity</Col>
              <Col md={1}>Price</Col>
              <Col md={3}>Timestamp</Col>
            </Row>
          
          {purchaseDetails.map((detail, index) => {
            const formattedDate = format(new Date(detail.date), "MMMM do, yyyy H:mma");
            return (
              <Row key={index} style={{ marginTop: "20px" }}>
                <Col md={3}>{detail.giftItem.name}</Col>
                <Col md={3}>{detail.user.email}</Col>
                <Col md={2}>{detail.count}</Col>
                <Col md={1}>{detail.currentPrice} point(s)</Col>
                <Col md={3}>{formattedDate}</Col>
              </Row>
            );
          })}
          </>
          )}
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add More {selectedGiftItem?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    value={enteredQuantity}
                    onChange={(e) => setEnteredQuantity(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              style={{ marginTop: "30px", background: "red", border: "none" }}
              variant="primary"
              type="submit"
              disabled={!enteredQuantity}
            >
              Add More
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Inventory;