import React, { createContext, useState, useEffect } from "react";
import { getDatabase, ref, set, get, push, update, onValue, runTransaction } from "firebase/database";
import { getDownloadURL, ref as storageRef, uploadBytes } from "firebase/storage";
import { auth, storage } from "../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";

export const BankContext = createContext();

export const BankProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateIsLoginStatus = async (userEmail, isLogin) => {
    if (!userEmail) return;
    const db = getDatabase();
    const emailKey = userEmail.replace(/\./g, ',');
    const userRef = ref(db, `users/${emailKey}`);
    try {
      await update(userRef, { isLogin });
      console.log(`isLogin status updated to ${isLogin} for user ${userEmail}`);
    } catch (error) {
      console.error("Error updating isLogin status:", error);
    }
  };

  const fetchUserData = async (user) => {
    const db = getDatabase();
    const emailKey = user.email.replace(/\./g, ',');
    const userRef = ref(db, `users/${emailKey}`);
  
    try {
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        let userData = snapshot.val();
        
        // Initialize balance and transferBalance if they don't exist
        if (typeof userData.balance === 'undefined') {
          userData.balance = 0;
          await update(userRef, { balance: 0 });
        }
        if (typeof userData.transferBalance === 'undefined') {
          userData.transferBalance = 0;
          await update(userRef, { transferBalance: 0 });
        }
  
        console.log("Fetched user data:", userData);
        setLoggedInUser({ 
          ...user, 
          ...userData,
          balance: parseFloat(userData.balance) || 0,
          transferBalance: parseFloat(userData.transferBalance) || 0,
          salary: parseFloat(userData.salary) || 0,
          isSalaryReceived: userData.isSalaryReceived || false
        });
      } else {
        console.warn("User data not found in the database for user:", user.uid);
        // Initialize new user data
        const newUserData = {
          email: user.email,
          balance: 0,
          transferBalance: 0,
          isActive: user.email === "admin@curaesoft.com", // Only true for admin
          isDeposit: user.email === "admin@curaesoft.com", // Only true for admin
          isWithdraw: user.email === "admin@curaesoft.com", // Only true for admin
          isTransfer: user.email === "admin@curaesoft.com", // Only true for admin
          firstName: user.displayName ? user.displayName.split(' ')[0] : '',
          lastName: user.displayName ? user.displayName.split(' ')[1] : '',
          isSalaryReceived: false
        };
        await set(userRef, newUserData);
        setLoggedInUser({ ...user, ...newUserData });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const listenToUserData = (userEmail) => {
    const db = getDatabase();
    const emailKey = userEmail.replace(/\./g, ',');
    const userRef = ref(db, `users/${emailKey}`);

    const unsubscribe = onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        setLoggedInUser((prevUser) => ({ ...prevUser, ...userData }));
        console.log("Real-time user data update:", userData);
      }
    });

    return unsubscribe;
  };

  const addUser = async (user, profileImage) => {
    setLoading(true);
    const db = getDatabase();
    const emailKey = user.email.replace(/\./g, ',');
    const newUserRef = ref(db, `users/${emailKey}`);

    try {
      if (profileImage) {
        const imageRef = storageRef(storage, `profileImages/${user.id}`);
        await uploadBytes(imageRef, profileImage);
        user.profileImagePath = `profileImages/${user.id}`;
        const imageUrl = await getDownloadURL(imageRef);
        user.profileImageUrl = imageUrl;
      }

      await set(newUserRef, {
        ...user,
        balance: 0,
        transferBalance: 0,
      });
      setUsers((prevUsers) => [...prevUsers, user]);
      return true;
    } catch (error) {
      console.error("Error adding user:", error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateFields = async (userEmail, fields) => {
    setLoading(true);
    const db = getDatabase();
    const emailKey = userEmail.replace(/\./g, ',');
    const userRef = ref(db, `users/${emailKey}`);
    console.log("Fields to update:", fields);
    try {
      await update(userRef, fields);
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.email === userEmail ? { ...user, ...fields } : user
        )
      );
      if (loggedInUser?.email === userEmail) {
        setLoggedInUser((prevUser) => ({ ...prevUser, ...fields }));
      }
    } catch (error) {
      console.error("Error updating fields:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateBalance = async (amount, type, userEmail = null) => {
    setLoading(true);
    const db = getDatabase();
    const emailKey = (userEmail || loggedInUser?.email).replace(/\./g, ',');
    const userRef = ref(db, `users/${emailKey}`);
  
    try {
      await runTransaction(userRef, (userData) => {
        if (userData) {
          let currentBalance = parseFloat(userData.balance) || 0;
          let currentTransferBalance = parseFloat(userData.transferBalance) || 0;
          let currentSalary = parseFloat(userData.salary) || 0;
  
          switch(type) {
            case "deposit":
              userData.balance = currentBalance + amount;
              break;
            case "depositSalary":
              userData.balance = currentBalance + currentSalary;
              userData.salary = 0;
              userData.isSalaryReceived = false;
              break;
            case "withdraw":
            case "purchase":
              if (currentTransferBalance >= amount) {
                userData.transferBalance = currentTransferBalance - amount;
              } else {
                const remainingAmount = amount - currentTransferBalance;
                userData.transferBalance = 0;
                userData.balance = currentBalance - remainingAmount;
              }
              break;
            case "send":
              if (currentBalance + currentTransferBalance < amount) {
                throw new Error("Insufficient funds");
              }
              if (currentBalance >= amount) {
                userData.balance = currentBalance - amount;
              } else {
                const remainingAmount = amount - currentBalance;
                userData.balance = 0;
                userData.transferBalance = currentTransferBalance - remainingAmount;
              }
              break;
            case "receive":
              userData.transferBalance = currentTransferBalance + amount;
              break;
            default:
              throw new Error("Invalid transaction type");
          }
  
          const runningBalance = parseFloat(userData.balance + userData.transferBalance).toFixed(2);
  
          if (!userData.transactions) {
            userData.transactions = {};
          }
          const newTransactionKey = push(ref(db, `transactions/${emailKey}`)).key;
          userData.transactions[newTransactionKey] = {
            type,
            amount: ["withdraw", "send", "purchase"].includes(type) ? -amount : amount,
            date: new Date().toISOString(),
            runningBalance: runningBalance,
          };
        }
        return userData;
      });
  
      if (loggedInUser?.email === emailKey.replace(/,/g, '.')) {
        const updatedUserData = await get(userRef);
        setLoggedInUser((prevUser) => ({ 
          ...prevUser, 
          ...updatedUserData.val(),
          balance: parseFloat(updatedUserData.val().balance) || 0,
          transferBalance: parseFloat(updatedUserData.val().transferBalance) || 0,
          salary: parseFloat(updatedUserData.val().salary) || 0,
          isSalaryReceived: updatedUserData.val().isSalaryReceived || false
        }));
      }
  
      console.log(`Balance updated for user: ${emailKey}`);
    } catch (error) {
      console.error("Error updating balance:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };


  const updateUserAfterTransfer = async (amount) => {
    const db = getDatabase();
    const emailKey = loggedInUser.email.replace(/\./g, ',');
    const userRef = ref(db, `users/${emailKey}`);
  
    try {
      let updatedData = null;
      await runTransaction(userRef, (userData) => {
        if (userData) {
          let newBalance = userData.balance || 0;
          let newTransferBalance = userData.transferBalance || 0;
  
          if (newBalance >= amount) {
            newBalance -= amount;
          } else {
            const remainingAmount = amount - newBalance;
            newBalance = 0;
            newTransferBalance -= remainingAmount;
          }
  
          userData.balance = newBalance;
          userData.transferBalance = newTransferBalance;
          updatedData = { ...userData };
        }
        return userData;
      });
  
      // Update local state with the data from the transaction
      if (updatedData) {
        setLoggedInUser(prevUser => ({
          ...prevUser,
          balance: updatedData.balance,
          transferBalance: updatedData.transferBalance
        }));
      }
    } catch (error) {
      console.error("Error updating user after transfer:", error);
      throw error;
    }
  };

  useEffect(() => {
    let unsubscribeFromUserData = null;
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User authenticated:", user);
        fetchUserData(user);
        unsubscribeFromUserData = listenToUserData(user.email);
        updateIsLoginStatus(user.email, true);
      } else {
        console.warn("User not authenticated");
        setLoggedInUser(null);
      }
    });

    return () => {
      if (unsubscribeFromUserData) {
        unsubscribeFromUserData();
      }
      unsubscribe();
    };
  }, []);

  const logout = async () => {
    try {
      if (loggedInUser) {
        await updateIsLoginStatus(loggedInUser.email, false);
      }
      await auth.signOut();
      setLoggedInUser(null);
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <BankContext.Provider
      value={{
        users,
        setUsers,
        loggedInUser,
        setLoggedInUser,
        loading,
        setLoading,
        addUser,
        updateFields,
        updateBalance,
        updateUserAfterTransfer,
        logout,
      }}
    >
      {children}
    </BankContext.Provider>
  );
};