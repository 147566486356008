import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Col, Row, Container, Form, Button, Alert, Modal, Spinner } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate, NavLink } from "react-router-dom";

const Deposit = () => {
  const navigate = useNavigate();
  const { loggedInUser, loading, setLoading, updateBalance, updateFields } = useContext(BankContext);
  const [deposit, setDeposit] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const alertShown = useRef(false);

  const handleDeposit = async (e) => {
    e.preventDefault();
    const depositAmount = parseFloat(deposit);

    if (!loggedInUser) {
      setError("Please login first");
      return;
    }

    if (!loggedInUser?.isDeposit) {
      setError(
        "You can't deposit points. Please contact admin to activate the deposit feature."
      );
      return;
    }

    if (isNaN(depositAmount)) {
      setError("Please enter a valid number");
      return;
    }

    if (depositAmount <= 0) {
      setError("Deposit amount must be positive");
      return;
    }

    setLoading(true);

    try {
      await updateBalance(depositAmount, "deposit");
      setShowModal(true);
    } catch (error) {
      console.error("Error during deposit:", error);
      setError("Something went wrong with the deposit.");
    } finally {
      setLoading(false);
    }
  };

  const handleDepositSalary = async () => {
    if (!loggedInUser) {
      setError("Please login first");
      return;
    }
    const salaryAmount = parseFloat(loggedInUser?.salary);

    if (isNaN(salaryAmount) || salaryAmount <= 0) {
      setError("Invalid salary amount.");
      return;
    }

    setLoading(true);

    try {
      await updateBalance(salaryAmount, "deposit");
      await updateFields(loggedInUser.email, { salary: 0, isSalaryReceived: false }); // Reset salary after deposit
      setShowModal(true);
    } catch (error) {
      console.error("Error during salary deposit:", error);
      setError("Something went wrong with the salary deposit.");
    } finally {
      setLoading(false);
    }
  };
  const handleConfirm = () => {
    setDeposit("");
    setError("");
    setShowModal(false);
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    if (!loggedInUser && !alertShown.current) {
      alertShown.current = true;
      alert("Please Login");
      navigate("/login");
    }
  }, [loggedInUser, navigate]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} lg={9} className="order-lg-1">
            <Card className="text-left" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Body>
                <Card.Title>Deposit Appreciate360 Point(s)</Card.Title>

                <Form onSubmit={handleDeposit}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Account Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={`${loggedInUser?.firstName || 'Unknown'} ${loggedInUser?.lastName || 'User'}`}
                      disabled
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Account Balance</Form.Label>
                    <Form.Control
                      type="text"
                      value={`${((loggedInUser?.balance || 0) + (loggedInUser?.transferBalance || 0)).toFixed(2)} point(s)`}
                      disabled
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                  {loggedInUser?.isSalaryReceived && (
                    <>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          New Points Received
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={`${loggedInUser?.salary || 0} point(s)`}
                          disabled
                          style={{ textAlign: "center" }}
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        style={{
                          marginTop: "10px",
                          background: "red",
                          border: "none",
                        }}
                        onClick={handleDepositSalary}
                      >
                        Deposit New Points
                      </Button>
                    </>
                  )}

                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Deposit Amount
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={deposit}
                      onChange={(e) => setDeposit(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!deposit}
                    style={{ marginTop: "30px", background: "red", border: "none" }}
                  >
                    Deposit
                  </Button>
                </Form>
                {error && <Alert variant="danger">{error}</Alert>}
              </Card.Body>
            </Card>

            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Body>
                <Card.Title>
                  <h5>How to manage your Appreciate360 Point(s) Deposit</h5>
                </Card.Title>
                <p style={{ textAlign: 'left', fontSize: '14px' }}>The Appreciate360 system in coAmplifi manages deposits by accounting for all the points in your general balance account, which are points received from the system and deposited by you. You will not be able to withdraw or use points from your general account balance unless they are in your spending account. Your general balance includes a combination of points from regular credits and points given to you by other users. However, only the points gifted to you are available in your spending account for withdrawals. Points from your general account can also be gifted to other users as a gesture of appreciation.</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={3} className="order-lg-2">
            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Header>Welcome to your Appreciate360 v7.1.5</Card.Header>
              <Card.Body>
                <Card.Title style={{ marginBottom: '10px' }}>
                  {!loggedInUser ? (
                    'Please Login to view your account information.'
                  ) : (
                    <p style={{ textAlign: 'left', fontSize: '14px' }}>You are currently logged in to your Appreciate360 account in coAmplifi. <br /><br />
                      You may execute transactions in your account using the tabs in the navigation menu.</p>
                  )}
                </Card.Title>
              </Card.Body>
            </Card>

            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Header>Appreciate360 Information</Card.Header>
              <Card.Body>
                <Card.Title style={{ marginBottom: '10px' }}>
                  {loggedInUser ? (
                    <ul style={{ textAlign: 'left', fontSize: '14px', listStyleType: 'circle' }}>
                      <li>
                        <NavLink to="/home">Home</NavLink> - Access your home page by selecting the coAmplifi logo.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/deposit">Deposit</NavLink> - Add points into your account and see the increase in your balance.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/withdraw">Withdraw</NavLink> - Subtract points from your account and see the decrease in your balance.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/transfer">Appreciate</NavLink> - Move points from your balance to another user and see the points added to their account and subtracted from yours.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/giftshop">Gift Shop</NavLink> - Checkout items in the giftshop that you might be interested in.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/cart">Cart</NavLink> - See your cart and its contents ready to buy.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/purchased-items">Purchased</NavLink> - See purchases that have occurred in your account.
                      </li>
                    </ul>
                  ) : (
                    'Please login to see account-related information.'
                  )}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {loading ? (
        <Spinner />
      ) : (
        <Modal show={showModal} onHide={handleConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>Successfully Deposited</Modal.Title>
              </Modal.Header>
                <Modal.Body>
                  {`Successfully deposited ${deposit || loggedInUser?.salary} point(s) in your account. 
                  Your new balance is ${((loggedInUser?.balance || 0) + (loggedInUser?.transferBalance || 0)).toFixed(2)} point(s).`}
                    </Modal.Body>
                    <Modal.Footer>
                  <Button variant="danger" onClick={handleConfirm}>
                  Ok
                </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default Deposit;