import image from "../assets/images/vq_appreciate360.jpg";
import React, { useContext, useEffect, useState } from "react";
import { Card, Row, Form, Container, Col, Button, Alert, Spinner } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
import { signInWithEmailAndPassword } from "firebase/auth"; 
import { auth } from '../firebase/firebase';

const Login = () => {
  const { loading, loggedInUser, setLoggedInUser, setLoading } = useContext(BankContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters");
      return;
    }
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`);
      const snapshot = await get(userRef);
  
      if (snapshot.exists()) {
        const userData = snapshot.val();
        if (userData.isActive) {
          setLoggedInUser({ ...userData, uid: user.uid });
          localStorage.setItem("token", await user.getIdToken());
          navigate("/home"); // Redirect to home page immediately after successful login
        } else {
          setError("Your account is inactive.");
        }
      } else {
        setError("User does not exist.");
      }
    } catch (error) {
      console.error(error);
      if (error.code === 'auth/wrong-password') {
        setError("Incorrect password. Please try again.");
      } else if (error.code === 'auth/user-not-found') {
        setError("No user found with this email. Please check your email or sign up.");
      } else {
        setError("Invalid Credentials.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    auth.signOut();
    setLoggedInUser(null);
    navigate("/login");
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} lg={3} className="order-lg-2">
            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Header>{loggedInUser ? "You are currently logged in." : "Login"}</Card.Header>
              <Card.Body>
                {!loggedInUser ? (
                  <>
                    <Card.Title>Login</Card.Title>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                      <Button
                        style={{ marginTop: "30px", background: "red", border: "none" }}
                        variant="primary"
                        type="submit"
                        disabled={!email || !password}
                      >
                        Login
                      </Button>
                    </Form>
                    {error && <Alert variant="danger">{error}</Alert>}
                  </>
                ) : (
                  <>
                    <p>Please remember to logout when you are not using your account.</p>
                    <Button
                      style={{ marginTop: "10px", background: "red", border: "none" }}
                      variant="primary"
                      onClick={handleLogout}
                    >
                      Logout
                    </Button>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={9} className="order-lg-1">
            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Body>
                <Card.Header>Welcome to Appreciate360 by coAmplifi</Card.Header>
              </Card.Body>
              <Card.Body>
                <img src={image} alt="Points Management" style={{ width: '50%', height: 'auto' }}/>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {loading && <Spinner />}
    </>
  );
};

export default Login;