import React, { useContext } from "react";
import { Navbar, Nav, Tooltip, OverlayTrigger } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { BankContext } from "../context/BankContext";
import "./Navbar.css";
import logo from "../assets/images/vq_coamplifi.png";

const NavigationBar = () => {
  const { loggedInUser, logout } = useContext(BankContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // This will trigger the logout function from BankContext
    navigate("/login"); // Redirect to login after logout
  };

  return (
    <Navbar expand="lg" className="navbar">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="home-tooltip">Go to Home Page</Tooltip>}
          >
            <Nav.Link as={NavLink} to="/home">
              <Navbar.Brand href="/">
                <img
                  src={logo}
                  width="150"
                  height="40"
                  className="d-inline-block align-top"
                  alt="Logo"
                />
              </Navbar.Brand>
            </Nav.Link>
          </OverlayTrigger>
        </Nav>

        <Nav className="ms-auto">
          {loggedInUser ? (
            <>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="deposit-tooltip">Deposit Points</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/deposit">
                  Deposit
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="withdraw-tooltip">Withdraw Points</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/withdraw">
                  Withdraw
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="transfer-tooltip">Appreciate</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/transfer">
                  Appreciate
                </Nav.Link>
              </OverlayTrigger>

              {loggedInUser.email === "admin@curaesoft.com" && (
                <>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="all-data-tooltip">View All Data</Tooltip>}
                  >
                    <Nav.Link as={NavLink} to="/all-data">
                      All Data
                    </Nav.Link>
                  </OverlayTrigger>

                  <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="chatbot-tooltip">AI Chatbot</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/chatbot">
                  Support
                </Nav.Link>
              </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="manual-tooltip">Manage Manuals</Tooltip>}
                  >
                    <Nav.Link as={NavLink} to="/manual">
                      Manage Manuals
                    </Nav.Link>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="inventory-tooltip">Inventory</Tooltip>}
                  >
                    <Nav.Link as={NavLink} to="/inventory">
                      Inventory
                    </Nav.Link>
                  </OverlayTrigger>
                </>
              )}

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="gift-shop-tooltip">Gift Shop</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/gift-shop">
                  Gift Shop
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="cart-tooltip">My Cart</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/cart">
                  Cart
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="purchased-items-tooltip">Purchased Items</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/purchased-items">
                  Purchased
                </Nav.Link>
              </OverlayTrigger>

              

              <Nav.Link onClick={handleLogout}>
                Logout
              </Nav.Link>
            </>
          ) : (
            <>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="create-account-tooltip">Create a New Account</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/create-account">
                  Create Account
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="login-tooltip">Login to Account</Tooltip>}
              >
                <Nav.Link as={NavLink} to="/login">
                  Login
                </Nav.Link>
              </OverlayTrigger>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;