import React, { useEffect, useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NavigationBar from "./components/Navbar";
import Home from "./components/Home";
import CreateAccount from "./components/CreateAccount";
import Deposit from "./components/Deposit";
import Withdraw from "./components/Withdraw";
import AllData from "./components/AllData";
import { BankProvider, BankContext } from "./context/BankContext";
import Login from "./components/Login";
import Transfer from "./components/Transfer";
import GiftShop from "./components/GiftShop";
import Cart from "./components/Cart";
import PurchasedItems from "./components/PurchasedItems";
import Inventory from "./components/Inventory";
import Chatbot from "./components/Chatbot";
import ManualSectionForm from "./components/ManualSectionForm";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "./firebase/firebase";  // Import your initialized Firebase app

function AppRoutes() {
  const { setLoggedInUser } = useContext(BankContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth(app);  // Use the existing Firebase app instance
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, update the context with the user details
        setLoggedInUser(user);
      } else {
        // No user is signed in, reset the context
        setLoggedInUser(null);
      }
      setLoading(false);
    });

    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, [setLoggedInUser]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while determining auth status
  }

  return (
    <Router>
      <NavigationBar />
      <div className="container mt-4">
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/login" element={<Login />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/all-data" element={<AllData />} />
          <Route path="/gift-shop" element={<GiftShop />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/purchased-items" element={<PurchasedItems />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/chatbot" element={<Chatbot />} />
          <Route path="/manual" element={<ManualSectionForm />} />
          <Route path="/" element={<Navigate replace to="/home" />} />
        </Routes>
      </div>
    </Router>
  );
}

function App() {
  return (
    <BankProvider>
      <AppRoutes />
    </BankProvider>
  );
}

export default App;