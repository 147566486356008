import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Form,
  Button,
  Alert,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { getDatabase, ref, set, get, child } from "firebase/database";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const CreateAccount = () => {
  const { loading, setLoading } = useContext(BankContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    // Validate input fields
    if (!firstName) {
      setError("First name is required");
      return;
    }
    if (!lastName) {
      setError("Last name is required");
      return;
    }
    if (firstName.length < 3) {
      setError("First name must be at least 3 characters long");
      return;
    }
    if (lastName.length < 3) {
      setError("Last name must be at least 3 characters long");
      return;
    }
    if (!email) {
      setError("Email is required");
      return;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters");
      return;
    }

    setLoading(true);
    const auth = getAuth();

    try {
      // Create user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add user to Firebase Realtime Database
      const db = getDatabase();
      const sanitizedEmail = email.replace(/\./g, ","); // Sanitize email for Firebase key
      const usersRef = ref(db, `users/${sanitizedEmail}`);

      // Create a new user object
      const newUser = {
        id: user.uid, // Use Firebase generated UID
        firstName,
        lastName,
        email,
        isLogin: false,
        balance: 100, // Initial balance for new accounts
      };

      await set(usersRef, newUser);

      setShowModal(true);
    } catch (err) {
      console.error("Error creating user:", err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    // Reset form fields
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setError("");
    setShowModal(false);
  };

  useEffect(() => {
    // Auto-clear error messages after 5 seconds
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Create Account</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button
              style={{ marginTop: "30px", background: "red", border: "none" }}
              variant="primary"
              type="submit"
              disabled={!firstName || !lastName || !email || !password}
            >
              Create Account
            </Button>
          </Form>
          {error && <Alert variant="danger">{error}</Alert>}
        </Card.Body>
      </Card>
      {loading ? (
        <Spinner />
      ) : (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Account Created</Modal.Title>
          </Modal.Header>
          <Modal.Body>{`Congratulations ${firstName}! Your account has been created successfully and credited with a $100 account creation bonus. Enjoy banking with us. Would you like to add another account?`}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirm}>
              Add Another Account
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default CreateAccount;