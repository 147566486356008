import React, { useContext, useState, useEffect, useRef } from "react";
import { Button, Card, Col, Row, Modal, Form, Alert, Spinner } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, child, get, set, push, update, remove } from "firebase/database";
import { getDownloadURL, uploadBytesResumable, ref as storageRef } from "firebase/storage";
import { storage } from "../firebase/firebase";

const GiftShop = () => {
  const { loggedInUser, setLoading, loading } = useContext(BankContext);
  const [show, setShow] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [giftItem, setGiftItem] = useState({
    name: "",
    description: "",
    price: "",
    image: null,
    quantity: 0,
  });
  const [gifts, setGifts] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState("");
  const alertShown = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGifts = async () => {
      setLoading(true);
      const dbRef = ref(getDatabase(), "gifts");
      try {
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          const giftData = snapshot.val();
          console.log("Fetched gift data:", giftData);
          const fetchedGifts = await Promise.all(
            Object.entries(giftData).map(async ([key, gift]) => {
              let imageUrl = gift.image;
              if (gift.imagePath && !gift.image) {
                try {
                  imageUrl = await getDownloadURL(storageRef(storage, gift.imagePath));
                } catch (error) {
                  console.error("Error fetching image:", error);
                  imageUrl = "/path/to/default/image.jpg";
                }
              }
              const quantity = gift.quantity !== undefined ? parseInt(gift.quantity) : 0;
              console.log(`Gift ${key} quantity:`, quantity);
              return { 
                ...gift, 
                image: imageUrl, 
                _id: key,
                quantity: quantity
              };
            })
          );
          console.log("Processed gifts:", fetchedGifts);
          setGifts(fetchedGifts);
        } else {
          setGifts([]);
        }
      } catch (err) {
        console.error("Error fetching gifts:", err);
        setError("Failed to load gift items.");
      } finally {
        setLoading(false);
      }
    };

    if (!loggedInUser && !alertShown.current) {
      alertShown.current = true;
      alert("Please Login");
      navigate("/login");
    } else if (loggedInUser) {
      fetchGifts();
    }
  }, [loggedInUser, navigate, setLoading]);


  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setEditIndex(null);
    setGiftItem({ name: "", description: "", price: "", image: null, inventory: 0 });
    setImageFile(null);
    setImagePreview(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGiftItem((prev) => ({ ...prev, [name]: name === 'inventory' ? parseInt(value) : value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
    setGiftItem((prev) => ({
      ...prev,
      image: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const db = getDatabase();
      let giftData = {
        name: giftItem.name,
        description: giftItem.description,
        price: giftItem.price,
        inventory: parseInt(giftItem.inventory) || 0,
      };

      if (imageFile) {
        const storagePath = `giftImages/${giftItem.name}-${Date.now()}`;
        const storageRefInstance = storageRef(storage, storagePath);
        const uploadTask = await uploadBytesResumable(storageRefInstance, imageFile);
        const downloadUrl = await getDownloadURL(uploadTask.ref);
        giftData.imagePath = storagePath;
        giftData.image = downloadUrl;
      }

      if (editIndex !== null) {
        const giftId = gifts[editIndex]._id;
        const giftRef = ref(db, `gifts/${giftId}`);
        await update(giftRef, giftData);
        const updatedGifts = [...gifts];
        updatedGifts[editIndex] = { ...giftData, _id: giftId };
        setGifts(updatedGifts);
        alert("Updated Gift Item Successfully");
      } else {
        const newGiftRef = ref(db, `gifts/${giftItem.name}-${Date.now()}`);
        await set(newGiftRef, giftData);
        setGifts([...gifts, { ...giftData, _id: newGiftRef.key }]);
        alert("Added Gift Item Successfully");
      }
      handleClose();
    } catch (error) {
      console.error("Error submitting gift item:", error);
      setError("Something went wrong, item with this name may already exist");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setGiftItem(gifts[index]);
    setImagePreview(gifts[index].image);
    handleShow();
  };

  const handleRemove = async (index) => {
    const giftId = gifts[index]._id;
    setLoading(true);
    try {
      const db = getDatabase();
      await remove(ref(db, `gifts/${giftId}`));

      const updatedGifts = gifts.filter((_, i) => i !== index);
      setGifts(updatedGifts);
      alert("Deleted Gift Item Successfully");
    } catch (err) {
      console.error("Error removing gift item:", err);
      setError("Failed to delete gift item.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddToCart = async (giftItem) => {
    if (!loggedInUser) {
      alert("Please login to add items to your cart.");
      return;
    }

    if (!giftItem || !giftItem._id) {
      console.error("Invalid gift item:", giftItem);
      setError("Failed to add item to cart: Invalid item data.");
      return;
    }

    try {
      setLoading(true);
      const db = getDatabase();
      
      // Check if the item is in stock
      if (giftItem.quantity <= 0) {
        setError("This item is out of stock.");
        return;
      }

      const cartRef = ref(db, `carts/${loggedInUser.email.replace(/\./g, ',')}`);
      const newCartItemRef = push(cartRef);
      
      const cartItemData = {
        giftItemId: giftItem._id,
        name: giftItem.name,
        description: giftItem.description,
        price: giftItem.price,
        imagePath: giftItem.imagePath,
        quantity: 1,
        addedAt: new Date().toISOString()
      };

      await set(newCartItemRef, cartItemData);

      alert("Added To Cart Successfully");
    } catch (err) {
      console.error("Error adding item to cart:", err);
      setError("Failed to add item to cart.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Gift Shop</Card.Title>
          <Card.Text>
            {loggedInUser?.email === "admin@curaesoft.com" && (
              <Row>
                <Col md={12}>
                  <Button
                    style={{
                      background: "red",
                      border: "none",
                      float: "right",
                    }}
                    variant="primary"
                    onClick={handleShow}
                  >
                    Add Gift Item
                  </Button>
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: "20px" }}>
              {loading ? (
                <Spinner animation="border" />
              ) : (
                gifts.map((gift, index) => (
                  <Col key={gift._id} md={4} className="mb-4">
                    <Card>
                      <div style={{ padding: "10px" }}>
                        <Card.Img
                          variant="top"
                          src={gift.image}
                          style={{ height: "200px", objectFit: "contain" }}
                        />
                      </div>
                      <Card.Body>
                        <Card.Title>{gift.name}</Card.Title>
                        <Card.Text>{gift.description}</Card.Text>
                        <Card.Text>{gift.price} point(s)</Card.Text>
                        <Card.Text>Available: {gift.quantity} item(s)</Card.Text>
                        {loggedInUser?.email === "admin@curaesoft.com" ? (
                          <>
                            <Button
                              variant="warning"
                              onClick={() => handleEdit(index)}
                              className="me-2"
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => handleRemove(index)}
                            >
                              Remove
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="warning"
                            className="me-2"
                            onClick={() => handleAddToCart(gift)}
                            disabled={gift.inventory <= 0}
                          >
                            Add To Cart
                          </Button>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editIndex !== null ? "Edit Gift Item" : "Add Gift Item"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={giftItem.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={giftItem.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={giftItem.price}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formInventory">
              <Form.Label>Inventory</Form.Label>
              <Form.Control
                type="number"
                name="inventory"
                value={giftItem.inventory}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formImage">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: "100%", marginTop: "10px" }}
                />
              )}
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              style={{
                background: "red",
                border: "none",
                marginTop: "10px",
              }}
              disabled={!giftItem.price || !giftItem.description || !giftItem.name}
            >
              {editIndex !== null ? "Update Gift Item" : "Add Gift Item"}
            </Button>
          </Form>
          {error && <Alert variant="danger">{error}</Alert>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GiftShop;