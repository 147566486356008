import React, { useContext, useEffect, useState } from "react";
import { Alert, Card, Col, Row, Spinner } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { format } from "date-fns";
import { storage } from "../firebase/firebase";

const PurchasedItems = () => {
  const { loggedInUser, setLoading, loading } = useContext(BankContext);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [purchasedItems, setPurchasedItems] = useState([]);

  useEffect(() => {
    const fetchPurchasedItems = async () => {
      if (!loggedInUser) {
        alert("Please Login");
        navigate("/login");
        return;
      }

      setLoading(true);
      try {
        const db = getDatabase();
        const purchasedItemsRef = ref(db, `purchasedItems/${loggedInUser.email.replace(/\./g, ',')}`);
        const snapshot = await get(purchasedItemsRef);

        if (snapshot.exists()) {
          const purchasedItemsData = snapshot.val();
          const fetchedPurchasedItems = await Promise.all(
            Object.entries(purchasedItemsData).map(async ([key, purchase]) => {
              const purchaseItems = await Promise.all(
                Object.entries(purchase.items).map(async ([itemKey, item]) => {
                  let imageUrl = "/path/to/default/image.jpg";
                  if (item.imagePath) {
                    try {
                      imageUrl = await getDownloadURL(storageRef(storage, item.imagePath));
                    } catch (imageError) {
                      console.error("Error fetching image:", imageError);
                    }
                  }
                  return {
                    ...item,
                    id: itemKey,
                    image: imageUrl,
                  };
                })
              );
              return {
                id: key,
                items: purchaseItems,
                purchaseDate: purchase.purchaseDate,
                totalAmount: purchase.totalAmount,
              };
            })
          );
          setPurchasedItems(fetchedPurchasedItems);
        } else {
          setPurchasedItems([]);
        }
      } catch (err) {
        console.error("Failed to load purchased items:", err);
        setError("Failed to load purchased items. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedItems();
  }, [loggedInUser, navigate, setLoading]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>Purchased Items</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {purchasedItems.length === 0 ? (
          <p>No purchased items found.</p>
        ) : (
          purchasedItems.map((purchase) => (
            <Card key={purchase.id} className="mb-3">
              <Card.Header>
                Purchase Date: {format(new Date(purchase.purchaseDate), "MMMM do, yyyy H:mma")}
                <br />
                Total Amount: {purchase.totalAmount} point(s)
              </Card.Header>
              <Card.Body>
                <Row>
                  {purchase.items.map((item) => (
                    <Col key={item.id} md={4} className="mb-4">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item.image}
                          style={{ height: "200px", objectFit: "contain", padding: "10px" }}
                        />
                        <Card.Body>
                          <Card.Title>{item.name}</Card.Title>
                          <Card.Text>{item.description}</Card.Text>
                          <Card.Text>{item.price} point(s)</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          ))
        )}
      </Card.Body>
    </Card>
  );
};

export default PurchasedItems;