import React, { useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { getDatabase, ref, set } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDownloadURL, uploadBytesResumable, ref as storageRef } from "firebase/storage";
import { storage } from "../firebase/firebase"; 

const ManualSectionForm = () => {
  const [key, setKey] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      setMessage('You must be logged in to submit this form.');
      setLoading(false);
      return;
    }

    try {
      const db = getDatabase();
      const manualSectionRef = ref(db, `manual/${key}`);

      // If a file is selected, upload it to Firebase Storage
      let fileUrl = '';
      if (file) {
        const fileRef = storageRef(storage, `manualFiles/${key}/${file.name}`);
        const uploadTask = await uploadBytesResumable(fileRef, file);
        fileUrl = await getDownloadURL(uploadTask.ref);
      }

      // Save the manual section content (and file URL if applicable) to the Realtime Database
      await set(manualSectionRef, {
        content,
        ...(fileUrl && { fileUrl }), // Include fileUrl if file was uploaded
        author: user.email
      });

      setMessage('Manual section saved successfully.');
      setKey('');
      setContent('');
      setFile(null);
    } catch (error) {
      setMessage('Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Add or Update Manual Section</Card.Title>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formKey">
            <Form.Label>Key</Form.Label>
            <Form.Control
              type="text"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              placeholder="Enter the key (e.g., transfer_points)"
              required
            />
          </Form.Group>
          <Form.Group controlId="formContent" style={{ marginTop: '10px' }}>
            <Form.Label>Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Enter the content for this manual section"
              required
            />
          </Form.Group>
          <Form.Group controlId="formFile" style={{ marginTop: '10px' }}>
            <Form.Label>Upload File (optional)</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit" style={{ marginTop: '10px' }} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Form>
        {message && <p style={{ marginTop: '10px' }}>{message}</p>}
      </Card.Body>
    </Card>
  );
};

export default ManualSectionForm;