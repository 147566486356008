import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Row, Col, Container, Form, Button, Alert, Modal, Spinner } from "react-bootstrap";
import { BankContext } from "../context/BankContext";
import { useNavigate, NavLink } from "react-router-dom";

const Transfer = () => {
  const navigate = useNavigate();
  const { loggedInUser, loading, setLoading, updateBalance } = useContext(BankContext);
  const [transfer, setTransfer] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const alertShown = useRef(false);

  const handleTransfer = async (e) => {
    e.preventDefault();
    const transferAmount = parseFloat(transfer);

    if (!loggedInUser) {
      setError("Please login first");
      return;
    }
    if (!loggedInUser?.isTransfer) {
      setError("You can't transfer points. Please contact admin to activate the transfer feature.");
      return;
    }
    if (!receiverEmail) {
      setError("Receiver email is required");
      return;
    }
    if (isNaN(transferAmount)) {
      setError("Please enter a valid number");
      return;
    }
    if (transferAmount <= 0) {
      setError("Transfer amount must be positive");
      return;
    }

    const totalBalance = (loggedInUser.balance || 0) + (loggedInUser.transferBalance || 0);
    if (transferAmount > totalBalance) {
      setError("Insufficient funds");
      return;
    }

    setLoading(true);

    try {
      // Deduct amount from the sender
      await updateBalance(transferAmount, "send");

      // Add amount to the receiver
      await updateBalance(transferAmount, "receive", receiverEmail);

      setShowModal(true);
    } catch (error) {
      console.error("Error during transfer:", error);
      setError(error.message || "Something went wrong with the transfer.");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setTransfer("");
    setReceiverEmail("");
    setError("");
    setShowModal(false);
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    if (!loggedInUser && !alertShown.current) {
      alertShown.current = true;
      alert("Please Login");
      navigate("/login");
    }
  }, [loggedInUser, navigate]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} lg={9} className="order-lg-1">
            <Card className="text-left" style={{ marginTop: "20px", marginRight: "20px" }}>
              <Card.Body>
                <Card.Title>Appreciate Someone</Card.Title>

                <Form onSubmit={handleTransfer}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Account Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={loggedInUser?.firstName + " " + loggedInUser?.lastName}
                      disabled
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Account Balance</Form.Label>
                    <Form.Control
                      type="text"
                      value={`${((loggedInUser?.balance || 0) + (loggedInUser?.transferBalance || 0)).toFixed(2)} point(s)`}
                      disabled
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Receiver Email</Form.Label>
                    <Form.Control
                      type="text"
                      value={receiverEmail}
                      onChange={(e) => setReceiverEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: "bold" }}>Appreciation Amount</Form.Label>
                    <Form.Control
                      type="text"
                      value={transfer}
                      onChange={(e) => setTransfer(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!transfer}
                    style={{ marginTop: "30px", background: "red", border: "none" }}
                  >
                    Appreciate
                  </Button>
                </Form>
                {error && <Alert variant="danger">{error}</Alert>}
              </Card.Body>
            </Card>

            <Card className="text-center" style={{ marginTop: "20px", marginRight: "20px" }}>
              <Card.Body>
                <Card.Title>
                  <h5>How to manage your Appreciation Point(s) Transfers</h5>
                </Card.Title>
                <p style={{ textAlign: "left", fontSize: "14px" }}>
                  The Appreciate360 system in coAmplifi manages your transfers and gifts to other users by tracking all the points you have given and received. Points that have been gifted to you go directly to your spending account and can be spent at the gift shop. Points deposited through your regular credits are added to your general balance and can only be gifted to other users; they cannot be used for purchases in the gift shop.
                </p>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} lg={3} className="order-lg-2">
            <Card className="text-center" style={{ marginTop: "20px", marginRight: "20px" }}>
              <Card.Header>Welcome to your Appreciate360 v7.1.5</Card.Header>
              <Card.Body>
                <Card.Title style={{ marginBottom: "10px" }}>
                  {!loggedInUser ? (
                    "Please Login to view your account information."
                  ) : (
                    <p style={{ textAlign: "left", fontSize: "14px" }}>
                      You are currently logged in to your Appreciate360 account in coAmplifi. <br />
                      <br />
                      You may execute transactions in your account using the tabs in the navigation menu.
                    </p>
                  )}
                </Card.Title>
              </Card.Body>
            </Card>

            <Card className="text-center" style={{ marginTop: '20px', marginRight: '20px' }}>
              <Card.Header>Appreciate360 Information</Card.Header>
              <Card.Body>
                <Card.Title style={{ marginBottom: '10px' }}>
                  {loggedInUser ? (
                    <ul style={{ textAlign: 'left', fontSize: '14px', listStyleType: 'circle' }}>
                      <li>
                        <NavLink to="/home">Home</NavLink> - Access your home page by selecting the coAmplifi logo.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/deposit">Deposit</NavLink> - Add points into your account and see the increase in your balance.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/withdraw">Withdraw</NavLink> - Subtract points from your account and see the decrease in your balance.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/transfer">Appreciate</NavLink> - Move points from your balance to another user and see the points added to their account and subtracted from yours.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/giftshop">Gift Shop</NavLink> - Checkout items in the giftshop that you might be interested in.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/cart">Cart</NavLink> - See your cart and its contents ready to buy.
                      </li>
                      <br />
                      <li>
                        <NavLink to="/purchased-items">Purchased</NavLink> - See purchases that have occurred in your account.
                      </li>
                    </ul>
                  ) : (
                    'Please login to see account-related information.'
                  )}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {loading ? (
        <Spinner />
      ) : (
        <Modal show={showModal} onHide={handleConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>Successfully Transferred</Modal.Title>
          </Modal.Header>
          <Modal.Body>{`Successfully transferred ${transfer} point(s) to ${receiverEmail}. 
           Your new balance is ${((loggedInUser?.balance || 0) + (loggedInUser?.transferBalance || 0)).toFixed(2)} point(s).`}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleConfirm}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};


export default Transfer;